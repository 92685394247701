import React, { useState, createContext, useEffect } from 'react'
import { useRouter } from 'next/router'

const HistoryContext = createContext(null)

function HistoryProvider(props: any) {
  const [path, setPath] = useState<any>({ curr: '', prev: '' })
  const [lastPage, setLastPage] = useState<any>(1)
  const { asPath } = useRouter()

  useEffect(() => {
    if (path.curr !== asPath) {
      setPath(({ prev, curr }: any) => ({ prev: curr, curr: asPath }))
    }
  }, [asPath])

  const { children } = props
  return (
    <HistoryContext.Provider
      value={
        {
          path,
          lastPage,
          setLastPage,
        } as any
      }
    >
      {children}
    </HistoryContext.Provider>
  )
}

export { HistoryContext, HistoryProvider }
