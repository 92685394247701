import axios from 'axios'
import C from '@utilities/constants'
import https from 'https'

const fetchUser = async (token?: string | null) => {
  if (!token) return C.EMPTY.USER_DETAILS

  try {
    const options = {
      baseURL: process.env.NEXT_PUBLIC_AUCTION_API,
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const [{ data }, { data: isSubscribed }] = await Promise.all([
      axios.get(`/account/dashboardInfo`, options),
      axios.get(`/search/isSubscribed`, options),
    ])
    if (!data?.profile || data?.success === false) {
      return C.EMPTY.USER_DETAILS
    }

    return { ...data, isSubscribed }
  } catch (e: any) {
    return C.EMPTY.USER_DETAILS
  }
}

export default fetchUser
