import getTokenTimeLeft from './getTokenTimeLeft'

const SECOND = 1000
const MINUTE = 60 * SECOND
const HOUR = MINUTE * 60

const AHEAD = 5 * SECOND

// used due server unavoidable delays
// when token already expired but received from the request
const isTokenExpiredAhead = (accessToken: string | null | undefined) => {
  return getTokenTimeLeft(accessToken) <= AHEAD
}

export default isTokenExpiredAhead
