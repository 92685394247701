import { NextApiRequest, NextApiResponse } from 'next'
import { deleteCookie } from 'cookies-next'
import C from '../../constants'

function invalidateServerSideAccount(context: { req: NextApiRequest, res: NextApiResponse }) {
  deleteCookie(C.TOKEN.NAME, {
    httpOnly: true,
    secure: process.env.NODE_ENV !== 'development',
    path: '/',
    ...context,
  })
}

export default invalidateServerSideAccount
