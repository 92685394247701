import C from '../constants'
import { Location as LocationType } from '@components/Location/location.types'

const groupByState = (locations: LocationType[]) => {
  const states: any = {}
  const { STATE_ABBREVIATIONS }: any = C

  locations.forEach((location) => {
    const stateName: string = STATE_ABBREVIATIONS[location.state]
      ? STATE_ABBREVIATIONS[location.state]
      : location.state
    if (states[stateName]) {
      if (states[stateName][location.city]) {
        states[stateName][location.city].push(location)
      } else {
        states[stateName][location.city] = [location]
      }
    } else {
      states[stateName] = { [location.city]: [location] }
    }
  })

  return states
}

export default groupByState
