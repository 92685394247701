import { useEffect, useState } from 'react'
import useScrollDirection from '@utilities/hooks/useScrollDirection'
import { HiArrowUp } from 'react-icons/hi'

const BackToTop = (): any => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const scrollDirection = useScrollDirection()

  const listenToScroll = () => {
    let minHeightToShow = 200
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    if (winScroll > minHeightToShow) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  return (
    <button
      type="button"
      data-te-ripple-color="light"
      onClick={() =>
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
      className={`print:hidden z-10 fixed bottom-[4.5rem] right-[1.2rem] duration-300 ease-in-out ${
        isVisible && scrollDirection === 'up'
          ? 'h-[48px] w-[48px] md:h-12'
          : 'h-0 w-0'
      } md:hover:h-14 hover:shadow-2xl rounded-full ${
        isVisible && scrollDirection === 'up'
          ? 'opacity-100'
          : 'opacity-0 cursor-default'
      } bg-bidfta-yellow-legacy text-[#6D5218] p-3`}
    >
      <HiArrowUp className="w-full h-full" />
    </button>
  )
}

export default BackToTop
