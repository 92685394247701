import { Location, Locations } from '@components/Location/location.types'
import _sortBy from 'lodash/sortBy'

export const getDistanceFromLocation = (
  locationId: number,
  locationsInArea: Locations | null
): string | undefined => {
  if (!locationsInArea) return undefined
  const locationInArea = locationsInArea.find(
    (locationInArea) => locationInArea.id === locationId
  )
  if (!locationInArea) return undefined

  return locationInArea.miles
}

export const populateLocationWithMiles = (
  location: Location,
  locationsInArea: Locations | null
) => {
  const miles = getDistanceFromLocation(location.id, locationsInArea)

  return miles ? { ...location, miles } : location
}
export const sortByDistance = (locations: Locations) =>
  _sortBy(locations, (obj) =>
    obj.miles === undefined ? Infinity : parseFloat(obj.miles)
  )

export const organizeLocations = (
  locationsOnMap: Locations,
  locationsInArea: Locations | null
) =>
  sortByDistance(
    locationsOnMap.map((location) =>
      populateLocationWithMiles(location, locationsInArea)
    )
  )
