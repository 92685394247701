import axios from 'axios'

const refreshToken = async () => {
  try {
    const { data } = await axios.get('/api/auth/access')
    return data?.data || null
  } catch (e) {
    console.error(e)
    return null
  }
}

export default refreshToken
