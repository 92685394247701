export const CATEGORIES = {
  APPLIANCES: {
    label: 'Appliances',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Appliances%2522%253A%255B%2522Refrigerators%252C%2520Freezers%2520%2526%2520Ice%2520Makers%2522%252C%2522Other%2522%252C%2522Small%2520Appliance%2520Parts%2520%2526%2520Accessories%2522%252C%2522Washers%2520%2526%2520Dryers%2522%252C%2522Ranges%252C%2520Ovens%2520%2526%2520Cooktops%2522%252C%2522Restaurant%2520Appliances%2520%2526%2520Equipment%2522%252C%2522Parts%2520%2526%2520Accessories%2522%252C%2522Range%2520Hoods%2522%252C%2522Dishwashers%2522%255D%257D',
    isFooter: true,
  },
  ARTS_CRAFTS_SEWING: {
    label: 'Arts, Crafts & Sewing',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Arts%252C%2520Crafts%2520%2526%2520Sewing%2522%253A%255B%2522Painting%252C%2520Drawing%2520%2526%2520Art%2520Supplies%2522%252C%2522Crafting%2522%252C%2522Other%2522%252C%2522Organization%252C%2520Storage%2520%2526%2520Transport%2522%252C%2522Scrapbooking%2520%2526%2520Stamping%2522%252C%2522Fabric%2522%252C%2522Beading%2520%2526%2520Jewelry%2520Making%2522%252C%2522Sewing%2522%252C%2522Knitting%2520%2526%2520Crochet%2522%252C%2522Printmaking%2522%252C%2522Needlework%2522%255D%257D',
    isFooter: false,
  },
  AUTOMOTIVE: {
    label: 'Automotive',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Automotive%2522%253A%255B%2522Replacement%2520Parts%2522%252C%2522Interior%2520Accessories%2522%252C%2522Exterior%2520Accessories%2522%252C%2522Car%2520Care%2522%252C%2522RV%2520Parts%2520%2526%2520Accessories%2522%252C%2522Lights%2520%2526%2520Lighting%2520Accessories%2522%252C%2522Motorcycle%2520%2526%2520Powersports%2522%252C%2522Tires%2520%2526%2520Wheels%2522%252C%2522Tools%2520%2526%2520Equipment%2522%252C%2522Heavy%2520Duty%2520%2526%2520Commercial%2520Vehicle%2520Equipment%2522%252C%2522Oils%2520%2526%2520Fluids%2522%252C%2522Other%2522%252C%2522Performance%2520Parts%2520%2526%2520Accessories%2522%252C%2522Paint%2520%2526%2520Paint%2520Supplies%2522%255D%257D',
    isFooter: true,
  },
  BABY_PRODUCTS: {
    label: 'Baby Products',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Baby%2520Products%2522%253A%255B%2522Safety%2522%252C%2522Nursery%2522%252C%2522Activity%2520%2526%2520Entertainment%2522%252C%2522Diapering%2522%252C%2522Feeding%2522%252C%2522Baby%2520Care%2522%252C%2522Car%2520Seats%2520%2526%2520Accessories%2522%252C%2522Strollers%2520%2526%2520Accessories%2522%252C%2522Travel%2520Gear%2522%252C%2522Gifts%2522%252C%2522Potty%2520Training%2522%252C%2522Pregnancy%2520%2526%2520Maternity%2522%252C%2522Other%2522%255D%257D',
    isFooter: false,
  },
  BEAUTY_AND_PERSONAL_CARE: {
    label: 'Beauty & Personal Care',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Beauty%2520%2526%2520Personal%2520Care%2522%253A%255B%2522Tools%2520%2526%2520Accessories%2522%252C%2522Hair%2520Care%2522%252C%2522Skin%2520Care%2522%252C%2522Personal%2520Care%2522%252C%2522Other%2522%252C%2522Oral%2520Care%2522%252C%2522Shave%2520%2526%2520Hair%2520Removal%2522%252C%2522Makeup%2522%252C%2522Foot%252C%2520Hand%2520%2526%2520Nail%2520Care%2522%252C%2522Fragrance%2522%252C%2522Salon%2520Care%2522%255D%257D',
    isFooter: false,
  },
  BOOKS: {
    label: 'Books',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Books%2522%253A%255B%2522Literature%2520%2526%2520Fiction%2522%252C%2522Other%2522%252C%2522Arts%2520%2526%2520Photography%2522%252C%2522Children%27s%2520Fairy%2520Tales%252C%2520Folk%2520Tales%2520%2526%2520Myths%2522%252C%2522Crafts%252C%2520Hobbies%2520%2526%2520Home%2522%252C%2522Biographies%2520%2526%2520Memoirs%2522%252C%2522Children%27s%2520Activities%252C%2520Crafts%2520%2526%2520Games%2522%252C%2522Calendars%2522%252C%2522Health%252C%2520Fitness%2520%2526%2520Dieting%2522%252C%2522Science%2520%2526%2520Math%2522%252C%2522Politics%2520%2526%2520Social%2520Sciences%2522%252C%2522Children%27s%2520Growing%2520Up%2520%2526%2520Facts%2520of%2520Life%2522%252C%2522Christian%2520Books%2520%2526%2520Bibles%2522%252C%2522Humor%2520%2526%2520Entertainment%2522%252C%2522Children%27s%2520Early%2520Learning%2522%252C%2522Computers%2520%2526%2520Technology%2522%252C%2522Mystery%252C%2520Thriller%2520%2526%2520Suspense%2522%252C%2522Education%2520%2526%2520Teaching%2522%252C%2522New%252C%2520Used%2520%2526%2520Rental%2520Textbooks%2522%252C%2522Reference%2522%252C%2522Teen%2520%2526%2520Young%2520Adult%2522%252C%2522Engineering%2520%2526%2520Transportation%2522%252C%2522History%2522%252C%2522Travel%2522%252C%2522Children%27s%2520Religions%2522%252C%2522Cookbooks%252C%2520Food%2520%2526%2520Wine%2522%255D%257D',
    isFooter: false,
  },
  CELL_PHONES_AND_ACCESSORIES: {
    label: 'Cell Phones & Accessories',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Cell%2520Phones%2520%2526%2520Accessories%2522%253A%255B%2522Cases%252C%2520Holsters%2520%2526%2520Sleeves%2522%252C%2522Accessories%2522%252C%2522Cell%2520Phones%2522%252C%2522Other%2522%252C%2522SIM%2520Cards%2520%2526%2520Prepaid%2520Minutes%2522%255D%257D',
    isFooter: false,
  },
  CLOTHING_ACCESSORIES: {
    label: 'Clothing & Accessories',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Clothing%252C%2520Shoes%2520%2526%2520Jewelry%2522%253A%255B%2522Luggage%2520%2526%2520Travel%2520Gear%2522%252C%2522Men%2522%252C%2522Women%2522%252C%2522Shoe%252C%2520Jewelry%2520%2526%2520Watch%2520Accessories%2522%252C%2522Uniforms%252C%2520Work%2520%2526%2520Safety%2522%252C%2522Novelty%2520%2526%2520More%2522%252C%2522Baby%2522%252C%2522Boys%2522%252C%2522Girls%2522%252C%2522Other%2522%252C%2522Costumes%2520%2526%2520Accessories%2522%255D%257D',
    isFooter: true,
  },
  ELECTRONICS: {
    label: 'Electronics',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Electronics%2522%253A%255B%2522Computers%2520%2526%2520Accessories%2522%252C%2522Television%2520%2526%2520Video%2522%252C%2522Home%2520Audio%2522%252C%2522Other%2522%252C%2522Headphones%2522%252C%2522Portable%2520Audio%2520%2526%2520Video%2522%252C%2522Accessories%2520%2526%2520Supplies%2522%252C%2522Camera%2520%2526%2520Photo%2522%252C%2522eBook%2520Readers%2520%2526%2520Accessories%2522%252C%2522Video%2520Projectors%2522%252C%2522Wearable%2520Technology%2522%252C%2522Security%2520%2526%2520Surveillance%2522%252C%2522GPS%252C%2520Finders%2520%2526%2520Accessories%2522%252C%2522Car%2520%2526%2520Vehicle%2520Car%2520Car%2520Audio%2522%252C%2522Car%2520%2526%2520Vehicle%2520Marine%2520Marine%2520Antennas%2522%252C%2522Car%2520%2526%2520Vehicle%2520Car%2520Electronics%2522%252C%2522Car%2520%2526%2520Vehicle%2520Car%2520Car%2520Safety%2520%2526%2520Security%2522%252C%2522Car%2520%2526%2520Vehicle%2520Car%2520Car%2520Video%2522%252C%2522Car%2520%2526%2520Vehicle%2520Marine%2520Marine%2520Speakers%2522%252C%2522Car%2520%2526%2520Vehicle%2520Electronics%2522%252C%2522Car%2520%2526%2520Vehicle%2520Vehicle%2520Electronics%2520Accessories%2522%252C%2522Car%2520%2526%2520Vehicle%2520Marine%2520Marine%2520Amplifiers%2522%255D%257D',
    isFooter: true,
  },
  GROCERY: {
    label: 'Grocery',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Grocery%2520%2526%2520Gourmet%2520Food%2522%253A%255B%2522Beverages%2522%252C%2522Candy%2520%2526%2520Chocolate%2522%252C%2522Snack%2520Foods%2522%252C%2522Other%2522%252C%2522Pantry%2520Staples%2522%252C%2522Food%2520%2526%2520Beverage%2520Gifts%2522%252C%2522Canned%252C%2520Jarred%2520%2526%2520Packaged%2520Foods%2522%252C%2522Pasta%2520%2526%2520Noodles%2522%252C%2522Breakfast%2520Foods%2522%252C%2522Condiments%2520%2526%2520Salad%2520Dressings%2522%252C%2522Jams%252C%2520Jellies%2520%2526%2520Sweet%2520Spreads%2522%252C%2522Dried%2520Beans%252C%2520Grains%2520%2526%2520Rice%2522%252C%2522Herbs%252C%2520Spices%2520%2526%2520Seasonings%2522%252C%2522Produce%2522%252C%2522Cooking%2520%2526%2520Baking%2522%252C%2522Fresh%2520Flowers%2520%2526%2520Live%2520Indoor%2520Plants%2522%252C%2522Breads%2520%2526%2520Bakery%2522%252C%2522Dairy%252C%2520Cheese%2520%2526%2520Eggs%2522%252C%2522Frozen%2522%252C%2522Olives%252C%2520Pickles%2520%2526%2520Relishes%2522%255D%257D',
    isFooter: true,
  },
  HEALTH_AND_HOUSEHOLD: {
    label: 'Health & Household',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Health%2520%2526%2520Household%2522%253A%255B%2522Medical%2520Supplies%2520%2526%2520Equipment%2522%252C%2522Health%2520Care%2522%252C%2522Wellness%2520%2526%2520Relaxation%2522%252C%2522Household%2520Supplies%2522%252C%2522Vitamins%2520%2526%2520Dietary%2520Supplements%2522%252C%2522Sports%2520Nutrition%2522%252C%2522Other%2522%252C%2522Sexual%2520Wellness%2522%252C%2522Vision%2520Care%2522%252C%2522Vitamins%252C%2520Minerals%2520%2526%2520Supplements%2522%252C%2522Baby%2520%2526%2520Child%2520Care%2522%252C%2522Stationery%2520%2526%2520Gift%2520Wrapping%2520Supplies%2522%252C%2522Stationery%2520%2526%2520Party%2520Supply%2522%252C%2522Diet%2520%2526%2520Sports%2520Nutrition%2522%255D%257D',
    isFooter: false,
  },
  HOME_KITCHEN: {
    label: 'Home & Kitchen',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Home%2520%2526%2520Kitchen%2522%253A%255B%2522Furniture%2522%252C%2522Home%2520D%25C3%25A9cor%2522%252C%2522Bedding%2522%252C%2522Kitchen%2520%2526%2520Dining%2522%252C%2522Storage%2520%2526%2520Organization%2522%252C%2522Bath%2522%252C%2522Heating%252C%2520Cooling%2520%2526%2520Air%2520Quality%2522%252C%2522Wall%2520Art%2522%252C%2522Event%2520%2526%2520Party%2520Supplies%2522%252C%2522Other%2522%252C%2522Seasonal%2520D%25C3%25A9cor%2522%252C%2522Vacuums%2520%2526%2520Floor%2520Care%2522%252C%2522Irons%2520%2526%2520Steamers%2522%252C%2522Kids%27%2520Home%2520Store%2522%252C%2522Home%2520D%25C3%25A9cor%2520Products%2522%255D%257D',
    isFooter: true,
  },
  INDUSTRIAL_AND_SCIENTIFIC: {
    label: 'Industrial & Scientific',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Industrial%2520%2526%2520Scientific%2522%253A%255B%2522Hydraulics%252C%2520Pneumatics%2520%2526%2520Plumbing%2522%252C%2522Material%2520Handling%2520Products%2522%252C%2522Food%2520Service%2520Equipment%2520%2526%2520Supplies%2522%252C%2522Occupational%2520Health%2520%2526%2520Safety%2520Products%2522%252C%2522Additive%2520Manufacturing%2520Products%2522%252C%2522Janitorial%2520%2526%2520Sanitation%2520Supplies%2522%252C%2522Adhesives%252C%2520Sealants%2520%2526%2520Lubricants%2522%252C%2522Industrial%2520Electrical%2522%252C%2522Raw%2520Materials%2522%252C%2522Packaging%2520%2526%2520Shipping%2520Supplies%2522%252C%2522Lab%2520%2526%2520Scientific%2520Products%2522%252C%2522Industrial%2520Hardware%2522%252C%2522Filtration%2522%252C%2522Cutting%2520Tools%2522%252C%2522Test%252C%2520Measure%2520%2526%2520Inspect%2522%252C%2522Commercial%2520Door%2520Products%2522%252C%2522Other%2522%252C%2522Power%2520Transmission%2520Products%2522%252C%2522Industrial%2520Power%2520%2526%2520Hand%2520Tools%2522%252C%2522Retail%2520Store%2520Fixtures%2520%2526%2520Equipment%2522%252C%2522Fasteners%2522%252C%2522Abrasive%2520%2526%2520Finishing%2520Products%2522%252C%2522Professional%2520Medical%2520Supplies%2522%252C%2522Science%2520Education%2522%252C%2522Tapes%252C%2520Adhesives%2520%2526%2520Sealants%2522%252C%2522Professional%2520Dental%2520Supplies%2522%255D%257D',
    isFooter: false,
  },
  MUSICAL_INSTRUMENTS: {
    label: 'Musical Instruments & Accessories',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Musical%2520Instruments%2522%253A%255B%2522Microphones%2520%2526%2520Accessories%2522%252C%2522Live%2520Sound%2520%2526%2520Stage%2522%252C%2522Electronic%2520Music%252C%2520DJ%2520%2526%2520Karaoke%2522%252C%2522Guitars%2522%252C%2522Keyboards%2520%2526%2520MIDI%2522%252C%2522Studio%2520Recording%2520Equipment%2522%252C%2522Drums%2520%2526%2520Percussion%2522%252C%2522Instrument%2520Accessories%2522%252C%2522Other%2522%252C%2522Amplifiers%2520%2526%2520Effects%2522%252C%2522Stringed%2520Instruments%2522%252C%2522Ukuleles%252C%2520Mandolins%2520%2526%2520Banjos%2522%252C%2522Wind%2520%2526%2520Woodwind%2520Instruments%2522%252C%2522Band%2520%2526%2520Orchestra%2522%252C%2522Bass%2520Guitars%2522%255D%257D',
    isFooter: false,
  },
  OFFICE_PRODUCTS: {
    label: 'Office Products',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Office%2520Products%2522%253A%255B%2522Office%2520Furniture%2520%2526%2520Lighting%2522%252C%2522Office%2520%2526%2520School%2520Supplies%2522%252C%2522Office%2520Electronics%2522%252C%2522Other%2522%255D%257D',
    isFooter: false,
  },
  LAWN_GARDEN: {
    label: 'Patio, Lawn & Garden',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Patio%252C%2520Lawn%2520%2526%2520Garden%2522%253A%255B%2522Outdoor%2520D%25C3%25A9cor%2522%252C%2522Patio%2520Furniture%2520%2526%2520Accessories%2522%252C%2522Outdoor%2520Power%2520Tools%2522%252C%2522Gardening%2520%2526%2520Lawn%2520Care%2522%252C%2522Other%2522%252C%2522Pool%2520%2526%2520Patio%2522%252C%2522Outdoor%2520Storage%2522%252C%2522Grills%2520%2526%2520Outdoor%2520Cooking%2522%252C%2522Outdoor%2520Heating%2520%2526%2520Cooling%2522%252C%2522Pools%252C%2520Hot%2520Tubs%2520%2526%2520Supplies%2522%252C%2522Pest%2520Control%2522%252C%2522Outdoor%2520Storage%2520%2526%2520Housing%2522%252C%2522Generators%2520%2526%2520Portable%2520Power%2522%252C%2522Farm%2520%2526%2520Ranch%2522%252C%2522Perky-Pet%2522%252C%2522Valley%2520Forge%2520Flag%2522%252C%2522Scotts%2520Miracle-Gro%2520Snap%2522%252C%2522Havahart%2522%252C%2522Char-Broil%2522%252C%2522Lawn%2520%2526%2520Garden%2520Low%2520Return%2520Rate%2520Alert%2522%252C%2522GreenWorks%2522%252C%2522Snow%2520Removal%2522%252C%2522Patio%252C%2520Lawn%2520%2526%2520Garden%2520Products%2520Eligible%2520for%2520Monthly%2520Payments%2522%252C%2522Roll%2520into%2520Spring%2522%252C%2522CobraCo%2522%252C%2522Toro%2522%252C%2522Lifetime%2522%252C%2522Home%2520Gift%2520Guide%253A%2520Featured%2520Dyna-Glo%2520and%2520Pleasant%2520Hearth%2520Products%2522%255D%257D',
    isFooter: true,
  },
  PET_SUPPLIES: {
    label: 'Pet Supplies',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Pet%2520Supplies%2522%253A%255B%2522Dogs%2522%252C%2522Dog%2520Food%2520%2526%2520Supplies%2522%252C%2522Cats%2522%252C%2522Other%2522%252C%2522Top%2520Dog%2520Supplies%2522%252C%2522Fish%2520%2526%2520Aquatic%2520Pets%2522%252C%2522Top%2520Cat%2520Supplies%2522%252C%2522Small%2520Animals%2522%252C%2522Reptiles%2520%2526%2520Amphibians%2522%252C%2522Pet%2520Supplies%2520Markdowns%2522%252C%2522Birds%2522%252C%2522Top%2520Pet%2520Products%2522%252C%2522Horses%2522%252C%2522AmazonBasics%2520pet%2520products%2522%252C%2522Bunny%2520Rabbit%2520Central%2522%252C%2522Pet%2520Behavior%2520Center%2522%252C%2522BarkBox%2520Deal%2520of%2520Day%2522%252C%2522Pet%2520Food%2522%252C%2522Pets%2520Subscribe%2520and%2520Save%2520Coupon%2522%252C%2522Hagen%2522%252C%2522Home%2520Gift%2520Guide%253A%2520Pets%2522%252C%2522Save%2520on%2520dog%2520%2526%2520cat%2520food%2522%252C%2522Pet%2520Content%2520-%2520Main%2522%255D%257D',
    isFooter: false,
  },
  SPORTS_AND_OUTDOORS: {
    label: 'Sports & Outdoors',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Sports%2520%2526%2520Outdoors%2522%253A%255B%2522Outdoor%2520Recreation%2522%252C%2522Exercise%2520%2526%2520Fitness%2522%252C%2522Sports%2522%252C%2522Fan%2520Shop%2522%252C%2522Hunting%2520%2526%2520Fishing%2522%252C%2522Accessories%2522%252C%2522Sports%2520%2526%2520Fitness%2522%252C%2522Other%2522%252C%2522Sports%2520%2526%2520Outdoor%2520Recreation%2520Accessories%2522%255D%257D',
    isFooter: false,
  },
  TOOLS_HOME_IMPROVEMENT: {
    label: 'Tools & Home Improvement',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Tools%2520%2526%2520Home%2520Improvement%2522%253A%255B%2522Lighting%2520%2526%2520Ceiling%2520Fans%2522%252C%2522Rough%2520Plumbing%2522%252C%2522Power%2520%2526%2520Hand%2520Tools%2522%252C%2522Kitchen%2520%2526%2520Bath%2520Fixtures%2522%252C%2522Storage%2520%2526%2520Home%2520Organization%2522%252C%2522Safety%2520%2526%2520Security%2522%252C%2522Other%2522%252C%2522Hardware%2522%252C%2522Electrical%2522%252C%2522Light%2520Bulbs%2522%252C%2522Paint%252C%2520Wall%2520Treatments%2520%2526%2520Supplies%2522%252C%2522Building%2520Supplies%2522%252C%2522Painting%2520Supplies%2520%2526%2520Wall%2520Treatments%2522%252C%2522Measuring%2520%2526%2520Layout%2520Tools%2522%252C%2522Welding%2520%2526%2520Soldering%2522%255D%257D',
    isFooter: true,
  },
  TOYS_AND_GAMES: {
    label: 'Toys & Games',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Toys%2520%2526%2520Games%2522%253A%255B%2522Party%2520Supplies%2522%252C%2522Collectible%2520Toys%2522%252C%2522Novelty%2520%2526%2520Gag%2520Toys%2522%252C%2522Tricycles%252C%2520Scooters%2520%2526%2520Wagons%2522%252C%2522Toy%2520Remote%2520Control%2520%2526%2520Play%2520Vehicles%2522%252C%2522Other%2522%252C%2522Stuffed%2520Animals%2520%2526%2520Plush%2520Toys%2522%252C%2522Games%2522%252C%2522Building%2520Toys%2522%252C%2522Arts%2520%2526%2520Crafts%2522%252C%2522Sports%2520%2526%2520Outdoor%2520Play%2522%252C%2522Puzzles%2522%252C%2522Play%2520Vehicles%2522%252C%2522Dress%2520Up%2520%2526%2520Pretend%2520Play%2522%252C%2522Learning%2520%2526%2520Education%2522%252C%2522Kids%27%2520Furniture%252C%2520D%25C3%25A9cor%2520%2526%2520Storage%2522%252C%2522Dolls%2520%2526%2520Accessories%2522%252C%2522Action%2520Figures%2520%2526%2520Statues%2522%252C%2522Toy%2520Figures%2520%2526%2520Playsets%2522%252C%2522Kids%27%2520Electronics%2522%252C%2522Baby%2520%2526%2520Toddler%2520Toys%2522%252C%2522Video%2520Games%2522%252C%2522Hobbies%2522%252C%2522Puppets%2520%2526%2520Puppet%2520Theaters%2522%252C%2522Games%2520%2526%2520Accessories%2522%252C%2522Vehicles%2522%252C%2522Preschool%2522%252C%2522Grown-Up%2520Toys%2522%255D%257D',
    isFooter: false,
  },
  OTHER: {
    label: 'Other',
    route:
      '/items?pageId=1&categoryJson=%257B%2522Other%2522%253A%255B%2522Other%2522%255D%257D',
    isFooter: false,
  },
}
