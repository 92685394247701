import axios from 'axios'
import { TokensResponse } from '../types'
import handleRequestTokenError from './handleRequestTokenError'
import isTokenExpired from '../isTokenExpired'

const requestToken = async (refreshToken: string): Promise<TokensResponse> => {
  try {
    const { data, status } = await axios.post(
      `${process.env.NEXT_PUBLIC_AUCTION_API}/auth/refresh`,
      { refreshToken: refreshToken }
    )
    if (status !== 200 || data?.success === false) {
      throw data
    }
    if (isTokenExpired(data?.accessToken)) {
      // pre-check the new token is valid
      throw 'the obtained token is invalid'
    }

    return data
  } catch (e: any) {
    return handleRequestTokenError(e)
  }
}

export default requestToken
