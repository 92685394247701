import Layout from '@components/Modal/Layout'
import { useContext } from 'react'
import { AuthContext } from '../../../contexts/AuthContext'

const Logout = ({ children }: { children?: any }) => {
  return (
    <Layout title="Logout" body={<LogoutBody />}>
      {children}
    </Layout>
  )
}

export const LogoutBody = () => {
  const { logout } = useContext<any>(AuthContext)

  return (
    <div className="p-6 text-center">
      <svg
        aria-hidden="true"
        className="mx-auto mb-4 text-gray-400 w-14 h-14"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <h3 className="mb-5 text-lg font-normal text-gray-500">
        Are you sure you want to logout?
      </h3>
      <button
        onClick={logout}
        data-modal-hide="popup-modal"
        type="button"
        className="text-white bg-bidfta-red hover:bg-bidfta-red focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
      >
        Yes, I'm sure
      </button>
    </div>
  )
}

export default Logout
