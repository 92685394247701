import { AuthOptions, DefaultSession } from 'next-auth'
import CredentialsProvider from 'next-auth/providers/credentials'
import C from '../constants'
import { Credentials } from '../auth/types'
import requestNewToken from '../auth/server/requestNewToken'
import { JWTSession } from './types'
import { refreshJWTSession } from './refreshJWTSession'
import isTokenExpiredAhead from '../auth/isTokenExpiredAhead'
import fetchUser from '@utilities/auth/server/fetchUser'
import { JWT } from '@utilities/next-auth/next-auth'

const authOptions: AuthOptions = {
  providers: [
    CredentialsProvider({
      credentials: {},
      async authorize(credentials) {
        const response = await requestNewToken(credentials as Credentials)

        if (response?.success === false) {
          throw response
        }
        return {
          id: '',
          ...response,
        }
      },
    }),
  ],
  cookies: {
    sessionToken: {
      name: C.TOKEN.NAME,
      options: {
        httpOnly: true,
        sameSite: 'lax',
        path: '/',
      },
    },
  },
  callbacks: {
    jwt: async ({ token, user, account }) => {
      if (account && user) {
        const token = user as JWTSession
        return { ...token }
      }

      let jwtSession = token as JWTSession
      if (
        !jwtSession.accessToken ||
        !isTokenExpiredAhead(jwtSession.accessToken)
      ) {
        return jwtSession
      }
      return await refreshJWTSession(jwtSession)
    },
    session: async ({ session, token }) => {
      let jwtSession = token as JWTSession
      if (jwtSession.error) {
        return {} as DefaultSession
      }

      if (!session?.user?.profile && token.accessToken) {
        session.user = await fetchUser(token.accessToken)
      }

      session.accessToken = token.accessToken
      return session
    },
  },
}

export default authOptions
