import { useState, createContext, useEffect } from 'react'

const GlobalUIContext = createContext(null)

function GlobalUIProvider(props: any) {
  const [mainOverflowY, setMainOverflowY] = useState<boolean>(false)
  const [condensedFooter, setCondensedFooter] = useState<boolean>(false)
  const [sidebar, setSideBar] = useState<{ visible: boolean; type: string }>({
    visible: false,
    type: '',
  })
  const [showLogout, setShowLogout] = useState<boolean>(false)
  const [tabVisible, setTabVisible] = useState<boolean>(true)
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      setTabVisible(true)
    } else {
      setTabVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)

    // return a cleanup function to remove the event listener
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])
  const { children } = props
  return (
    <GlobalUIContext.Provider
      value={
        {
          mainOverflowY,
          setMainOverflowY,
          condensedFooter,
          setCondensedFooter,
          showSidebar: sidebar.visible,
          setShowSidebar: (v: boolean) =>
            setSideBar((prevState) => ({ ...prevState, visible: v })),
          sidebar,
          setSideBar,
          showLogout,
          setShowLogout,
          tabVisible,
        } as any
      }
    >
      {children}
    </GlobalUIContext.Provider>
  )
}

export { GlobalUIContext, GlobalUIProvider }
