/*
(required) label = String to be shown in button
(required) theme = "blue", "blue-dark", "blue-light", "gray" etc... NOTE: Need to extend the applyTheme() function
if trying to render a color that isn't in it
(required if type=link) link = any url (internal or external)
(optional) type = default "button", or "link"
(required if type=button) callBack = function to fire on form submit or button click
(optional) style = default "solid", or "hollow"
(optional) buttonStyle = default "solid", or "hollow"
(optional) width = default "default", or "full" Note: if width is default button width is determined by length of label
(optional) size = default "default", "expanded", "expanded-sm", "xs"

<BidButton
    label="Sign In"
    theme="blue-light"
    link="/home"
    type="link"
    buttonStyle="hollow"
    width="full"
    size="expanded"
    hasArrow
/>

<BidButton
    label="Sign In"
    theme="blue-light"
    callBack="functionName"
/>
*/

import { useState, useEffect } from 'react'
import Link from 'next/link'
import classNames from 'classnames'

const BidButton = function (props: any) {
  const {
    id = null,
    label,
    theme,
    link,
    type,
    buttonStyle,
    buttonType,
    size,
    width,
    onClick,
    callBack,
    disabled = false,
    hasArrow,
    hasLeftArrow,
    success = false,
    fullHeight = false,
    className = '',
    buttonClassName = '',
    track,
    ...rest
  } = props
  const [buttonSize, setButtonSize] = useState('')
  const [arrowIsHovered, setArrowIsHovered] = useState(false)

  useEffect(() => {
    if (!buttonSize) {
      if (!size || size === 'default') {
        setButtonSize('py-2.5 text-10 md:text-xs')
      } else if (size === 'expanded') {
        setButtonSize('py-4 xl:py-3.5 text-sm xl:text-base')
      } else if (size === 'expanded-sm') {
        setButtonSize('py-2.5 text-13')
      } else if (size === 'xs') {
        setButtonSize('py-1 md:py-2 xl:py-2.5 text-10 xl:text-xs')
      } else if (size === 'xxs') {
        setButtonSize('py-1 text-10 xl:text-xs')
      } else if (size === 'md') {
        setButtonSize('py-2 text-15 md:text-md')
      } else if (size === 'lg') {
        setButtonSize('py-3 text-18 md:text-md')
      }
    }
  }, [buttonSize])

  const setArrowFill = () => {
    if (hasArrow || hasLeftArrow) {
      setArrowIsHovered((prevState) => !prevState)
    }
  }

  const getArrowFill = () => {
    if (buttonStyle === 'hollow') return arrowIsHovered ? '#FFFFFF' : '#201974'
    return arrowIsHovered ? '#297AF7' : '#FFFFFF'
  }

  const applyTheme = (isHollow: any) => {
    let isHollowStyles = ''
    let defaultStyles = ''

    switch (theme) {
      case 'blue':
        isHollowStyles = `border border-bidfta-blue text-bidfta-blue
                hover:text-white hover:bg-bidfta-blue focus:bg-bidfta-blue
                focus:text-white bg-white`
        defaultStyles = `border bg-bidfta-blue border-bidfta-blue text-white
                hover:text-bidfta-blue hover:border-bidfta-blue
                focus:border-bidfta-blue hover:bg-white focus:text-bidfta-blue
                focus:bg-white`
        break
      case 'blue-light':
        isHollowStyles = `border border-bidfta-blue-light text-bidfta-blue-light
                hover:text-white hover:bg-bidfta-blue-light focus:bg-bidfta-blue-light
                focus:text-white bg-white`
        defaultStyles = `border bg-bidfta-blue-light border-bidfta-blue-light text-white
                hover:text-bidfta-blue-light hover:border-bidfta-blue-light
                focus:border-bidfta-blue-light hover:bg-white focus:text-bidfta-blue-light
                focus:bg-white`
        break
      case 'blue-dark':
        isHollowStyles = `border border-bidfta-blue-dark text-bidfta-blue-dark
                hover:text-white hover:bg-bidfta-blue-dark focus:bg-bidfta-blue-dark
                focus:text-white bg-white`
        defaultStyles = `border bg-bidfta-blue-dark border-bidfta-blue-dark text-white
                hover:text-bidfta-blue-dark hover:border-bidfta-blue-dark
                focus:border-bidfta-blue-dark hover:bg-white focus:text-bidfta-blue-dark
                focus:bg-white`
        break
      case 'green':
        isHollowStyles = `border border-success text-white
                hover:text-white hover:bg-success focus:bg-success
                focus:text-white bg-white`
        defaultStyles = `border bg-success border-success text-white
                hover:text-success hover:border-success
                focus:border-success hover:bg-white focus:text-red
                focus:bg-white`
        break
      case 'red':
        isHollowStyles = `border border-bidfta-red text-white
                hover:text-white hover:bg-bidfta-pink focus:bg-bidfta-pink
                focus:text-white bg-white`
        defaultStyles = `border bg-bidfta-red border-bidfta-red text-white
                hover:text-bidfta-red hover:border-bidfta-red
                focus:border-bidfta-red hover:bg-white focus:text-red
                focus:bg-white`
        break
      case 'yellow':
        isHollowStyles = `border border-bidfta-yellow-legacy text-bidfta-yellow-legacy
                hover:text-white hover:bg-bidfta-yellow-legacy focus:bg-bidfta-yellow-legacy
                focus:text-white bg-white`
        defaultStyles = `border bg-bidfta-yellow-legacy border-bidfta-yellow-legacy text-gray-800
                hover:text-white hover:border-bidfta-blue-backdrop-light
                focus:border-bidfta-blue-backdrop-light hover:bg-bidfta-blue-backdrop-light focus:text-white
                focus:bg-bidfta-blue-backdrop-light`
        break
      default:
        break
    }
    return isHollow ? isHollowStyles : defaultStyles
  }

  return (
    <>
      {type === 'link' ? (
        <Link
          href={link}
          className={classNames(
            `block text-center font-bold rounded-md px-4
                            transition duration-300 ease-in-out ${
                              width === 'full' ? 'w-full' : 'w-40'
                            } ${buttonSize} ${
              buttonStyle === 'hollow' ? applyTheme(true) : applyTheme(false)
            } ${
              hasArrow || hasLeftArrow
                ? 'flex items-center align-middle justify-center'
                : ''
            }`,
            className
          )}
          onClick={(e) => {
            e.stopPropagation()
            if (onClick) {
              onClick(e)
            }
          }}
          onMouseEnter={setArrowFill}
          onMouseLeave={setArrowFill}
        >
          {hasLeftArrow && (
            <div className={'px-1'}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
            </div>
          )}
          {label}
          {hasArrow && (
            <svg
              width="15"
              height="8"
              viewBox="0 0 15 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2.5"
            >
              <path
                d="M14.3239 4.30277C14.5191 4.10751 14.5191 3.79093 14.3239 3.59567L11.1419 0.413685C10.9466 0.218423 10.63 0.218423 10.4348 0.413685C10.2395 0.608947 10.2395 0.925529 10.4348 1.12079L13.2632 3.94922L10.4348 6.77765C10.2395 6.97291 10.2395 7.28949 10.4348 7.48475C10.63 7.68001 10.9466 7.68001 11.1419 7.48475L14.3239 4.30277ZM0.551147 4.44922H13.9703V3.44922H0.551147V4.44922Z"
                fill={getArrowFill()}
              />
            </svg>
          )}
        </Link>
      ) : (
        <button
          {...rest}
          id={id}
          type={buttonType || 'submit'}
          className={`${buttonClassName} ${
            fullHeight ? 'h-full' : ''
          } flex justify-center items-center text-center font-bold rounded-md px-4
                    transition duration-300 ease-in-out ${
                      width === 'full' ? 'w-full' : 'w-40'
                    } ${buttonSize} ${
            success
              ? 'bg-success text-white'
              : disabled
              ? 'bg-gray-500'
              : buttonStyle === 'hollow'
              ? applyTheme(true)
              : applyTheme(false)
          } ${disabled ? 'cursor-not-allowed' : ''}`}
          onClick={(e: any) => {
            e.stopPropagation()
            if (track) {
              import('react-facebook-pixel')
                .then((x: any) => x.default)
                .then((ReactPixel: any) => {
                  ReactPixel.init('340924541426797') // facebookPixelId
                  ReactPixel.pageView()
                  ReactPixel.trackCustom('bid button', label)
                })
            }

            if (callBack) {
              callBack(e)
            }
            e.currentTarget.blur()
          }}
          disabled={disabled}
          onMouseEnter={setArrowFill}
          onMouseLeave={setArrowFill}
        >
          {hasLeftArrow && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
          )}
          {label}
          {hasArrow && (
            <svg
              width="15"
              height="8"
              viewBox="0 0 15 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-1.5"
            >
              <path
                d="M14.3239 4.30277C14.5191 4.10751 14.5191 3.79093 14.3239 3.59567L11.1419 0.413685C10.9466 0.218423 10.63 0.218423 10.4348 0.413685C10.2395 0.608947 10.2395 0.925529 10.4348 1.12079L13.2632 3.94922L10.4348 6.77765C10.2395 6.97291 10.2395 7.28949 10.4348 7.48475C10.63 7.68001 10.9466 7.68001 11.1419 7.48475L14.3239 4.30277ZM0.551147 4.44922H13.9703V3.44922H0.551147V4.44922Z"
                fill={getArrowFill()}
              />
            </svg>
          )}
        </button>
      )}
    </>
  )
}

export default BidButton
