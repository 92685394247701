import jwt_decode from 'jwt-decode'

// avoid js timeouts overflow https://developer.mozilla.org/en-US/docs/Web/API/setTimeout
const MAX_INT32 = Math.pow( 2, 31 ) - 1

const getTokenTimeLeft = (accessToken: string | null | undefined) => {
  if (!accessToken) return 0
  const { exp }: { exp: number } = jwt_decode(accessToken)
  const timeout = exp * 1000 - new Date().getTime()
  return Math.min(timeout, MAX_INT32)
}

export default getTokenTimeLeft
