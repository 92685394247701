import {useState, FC, ReactElement, ReactNode} from 'react'

interface Props {
  title: string
  body: ReactElement
  children: ReactNode
}

interface ModalProps {
  title: string
  body: ReactElement
  onClose: any
  isOpen: boolean
}

const Layout: FC<Props> = ({ title, body, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const onClose = () => setIsOpen(false)
  const onOpen = () => setIsOpen(true)

  return (
    <>
      <span onClick={onOpen} className={'w-full cursor-pointer'}>
        {children}
      </span>
      <Modal title={title} body={body} isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export const Modal: FC<ModalProps> = ({ onClose, title, body, isOpen }) => {
  if (!isOpen) return <></>
  return (
    <div onClick={onClose}>
      <div className="justify-center flex overflow-x-hidden overflow-y-auto fixed top-20 md:top-30 inset-0 z-50 outline-none focus:outline-none">
        <div
          className="relative w-auto my-6 mx-auto max-w-xs md:max-w-4xl"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="font-bold text-bidfta-gray-dark text-4xl text-center">
                {title}
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={onClose}
              >
                x
              </button>
            </div>
            {/*body*/}
            <div className="text-left p-4">{body}</div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </div>
  )
}
export default Layout
