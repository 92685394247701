import axios, { Method } from 'axios'
import https from 'https'
import getServerSideAccessToken from '@utilities/auth/server/getServerSideAccessToken'
import invalidateServerSideAccount from '@utilities/auth/server/invalidateServerSideAccount'

export default async function bidapi({
  method = 'GET' as Method,
  data = {},
  params = {},
  url = '',
  token = null,
  context = null,
  config = {},
}: any) {
  if (!token && context) {
    token = await getServerSideAccessToken(context, false)
  }

  const axiosConfig = {
    httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),
    headers: {},
  }

  if (token) {
    axiosConfig.headers = { Authorization: `Bearer ${token}` }
  }
  const instance = axios.create(axiosConfig)

  try {
    const configuration = {
      url,
      method,
      data,
      params,
      ...config,
    }
    const auctionsRequest = await instance.request(configuration)
    return auctionsRequest.data
  } catch (e: any) {
    const error = e.response?.data || e
    console.error(error.message || e)
    if (e?.response?.status == 401) {
      if (context) {
        invalidateServerSideAccount(context as any)
      }
      return {}
    }
    throw e
  }
}
