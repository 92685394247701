import { useEffect, useRef } from 'react'

const useDidMountEffect = (func: () => any, deps: any[]) => {
  const didMount = useRef(false)
  useEffect(() => {
    let result
    if (didMount.current) {
      result = func()
    } else {
      didMount.current = true
    }
    return result
  }, deps)
}

export default useDidMountEffect
