import axios from 'axios'
import { Credentials, TokensResponse } from '@utilities/auth/types'
import handleRequestTokenError from './handleRequestTokenError'

const requestNewToken = async (credentials: Credentials): Promise<TokensResponse> => {
  try {
    const { data, status } = await axios.post(
      `${process.env.NEXT_PUBLIC_AUCTION_API}/auth/login`,
      {
        ...credentials,
        deviceType: 'DEVICE_TYPE_PC',
      }
    )
    if (status !== 200 || data?.success === false) {
      throw data
    }
    return data
  } catch (e: any) {
    return handleRequestTokenError(e)
  }
}

export default requestNewToken
