import { useState, useRef, useEffect } from 'react'

export const useScrollDirection = () => {
  // Define a state variable to store the current scroll position
  const [scrollPosition, setScrollPosition] = useState(() =>
    typeof window !== 'undefined' ? window.scrollY : 0
  )
  // Define a state variable to store the scroll direction
  const [scrollDirection, setScrollDirection] = useState('up')
  // Define a ref variable to store the previous scroll position
  const prevScrollPosition = useRef(scrollPosition)
  // Define a threshold value to avoid detecting small changes
  const threshold = 150

  // Define a function to handle the scroll event and update the state variables
  const handleScroll = () => {
    // Get the current scroll position from the window object
    const currentScrollPosition = window.scrollY
    // Set the current scroll position as state
    setScrollPosition(currentScrollPosition)
    // Compare the current and previous scroll positions with the threshold value
    if (
      Math.abs(currentScrollPosition - prevScrollPosition.current) < threshold
    ) {
      // If the difference is smaller than the threshold, do nothing
      return
    }
    // If the difference is larger than the threshold, update the scroll direction state
    if (currentScrollPosition > prevScrollPosition.current) {
      // If the current scroll position is larger than the previous one, set the direction as down
      setScrollDirection('down')
    } else {
      // If the current scroll position is smaller than the previous one, set the direction as up
      setScrollDirection('up')
    }
    // Update the previous scroll position ref with the current value
    prevScrollPosition.current = currentScrollPosition
  }

  // Use an effect hook to register and unregister the scroll event listener
  useEffect(() => {
    // Add the handleScroll function as a listener to the window scroll event
    window.addEventListener('scroll', handleScroll)
    // Return a cleanup function to remove the listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []) // Run only once on mount

  // Return the scroll direction state as output of the custom hook
  return scrollDirection
}

export default useScrollDirection
