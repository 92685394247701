import { useContext } from 'react'
import { LogoutBody } from '../Modal/Logout'
import { GlobalUIContext } from '../../contexts/GlobalUIContext'

const Logout = () => {
  const { showLogout, setShowLogout } = useContext<any>(GlobalUIContext)
  if (!showLogout) return <></>
  return (
    <div onClick={() => setShowLogout(false)}>
      <div className="justify-center flex overflow-x-hidden overflow-y-auto fixed top-20 md:top-30 inset-0 z-60 outline-none focus:outline-none ">
        <div className="h-80 border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
          <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
            <h3 className="font-bold text-bidfta-gray-dark text-xl text-center">
              Logout
            </h3>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowLogout(false)}
            >
              x
            </button>
          </div>
          <div className={'p-4'}>
            <LogoutBody />
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </div>
  )
}

export default Logout
