import { TokensResponse } from '../types'
import C from '../../constants'

const handleRequestTokenError = (e: any): TokensResponse => {
  const error = e.response?.data || e
  return {
    ...C.TOKEN.EMPTY,
    success: false,
    message: error.message,
  }
}

export default handleRequestTokenError
