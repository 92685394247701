import { AxiosInstance } from 'axios'
import fetchUser from '@utilities/auth/server/fetchUser'

const DELAY = 1000
const MAX_WAIT = 5000

let promise: Promise<any> | null = null
let resolver: any

let timeout: NodeJS.Timeout

let moment = Date.now()
let delta = 0

const fetchUserAccumulative = (token?: string | null) => {
  clearTimeout(timeout)

  delta += Date.now() - moment
  moment = Date.now()
  if (resolver && delta > MAX_WAIT) {
    resolver()
  } else {
    timeout = setTimeout(() => {
      resolver()
    }, DELAY)
  }

  if (!promise) {
    delta = 0
    promise = new Promise(
      (resolve) =>
        (resolver = async () => {
          clearTimeout(timeout)
          resolver = null
          promise = null
          resolve(await fetchUser(token))
        })
    )
  }
  return promise
}

export default fetchUserAccumulative
