import { getCookie } from 'cookies-next'

const TIMEOUT = 1000

// By-design we check here if the client closed or reloaded N moment ago
export const checkClientUnload = (req: any) => {
  const value = Number(getCookie('clientUnloadTime', {req}))
  const unloadTime = !isNaN(value) ? value : 0

  return Date.now() - unloadTime < TIMEOUT
}
