import refreshToken from './refreshToken'
import isTokenExpiredAhead from '../isTokenExpiredAhead'
import { setCookie } from 'cookies-next'

// saved token
let token: any = undefined

// the moment of time when the token was obtained previously
let moment = Date.now()

// shared subscription
let promise: Promise<any> | null = null

const context = global as unknown as { unload?: number } & Partial<Window>

const onunload = () => {
  context.unload = Date.now()
  setCookie('clientUnloadTime', context.unload)
}
if (context.unload === undefined) {
  context.unload = 0
  context.addEventListener?.('beforeunload', onunload)
}

const refreshTokenPromised = () => {
  if (!promise) {
    let resolver: any
    promise = new Promise(
      (resolve) =>
        (resolver = (result: any) => {
          resolve(result)
          promise = null
          moment = Date.now()
          token = result
        })
    )
    navigator.locks // navigator.locks prevents race conditional session loss in multiple tabs mode
      .request('bidfta_refresh_access_token', () => refreshToken())
      .then((result) => {
        resolver(result)
      })
  }
  return promise
}

const refreshTokenCached = ({ force = false } = {}) => {
  if (token === undefined || force || isTokenExpiredAhead(token)) {
    return refreshTokenPromised()
  }
  return Promise.resolve(token)
}

export default refreshTokenCached
