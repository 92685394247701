import { useEffect, useMemo } from 'react'
import axios, { AxiosInstance } from 'axios'
import https from 'https'
import refreshToken from '@utilities/auth/client/refreshTokenCached'
import { Session } from 'next-auth'

const useInstance = (
  session: Session | null,
  logout: () => void
): AxiosInstance => {
  const instance = useMemo(
    () =>
      axios.create({
        baseURL: process.env.NEXT_PUBLIC_AUCTION_API,
        httpsAgent: new https.Agent({
          rejectUnauthorized: false,
        }),
      }),
    []
  )

  useEffect(() => {
    const requestInterceptorId = instance.interceptors.request.use(
      async (config) => {
        try {
          const token = await refreshToken()
          if (!token && session) {
            logout()
          }
          if (token) {
            config.headers.Authorization = `Bearer ${token}`
          }

          return config
        } catch (error) {
          return Promise.reject(error)
        }
      }
    )

    return () => {
      instance.interceptors.request.eject(requestInterceptorId)
    }
  }, [instance, session])

  return instance
}

export default useInstance
