import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import BidButton from '../../elements/BidButton/BidButton'

const MissingCreditCard = () => {
  const {
    userData: {
      profile: { isAddedCard, id },
    },
    isAuthenticated,
  } = useContext<any>(AuthContext)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (!isAuthenticated || !id || isAddedCard) return setIsOpen(false)
    const timeout = setTimeout(() => setIsOpen(true), 3000)

    return () => clearTimeout(timeout)
  }, [isAuthenticated, isAddedCard, id])

  if (!isOpen) return <></>

  return (
    <div onClick={() => setIsOpen(false)}>
      <div className="justify-center flex overflow-x-hidden overflow-y-auto fixed top-20 md:top-30 inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-4xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="font-bold text-bidfta-gray-dark text-xl text-center">
                Missing Credit Card?
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setIsOpen(false)}
              >
                x
              </button>
            </div>
            {/*body*/}
            <div className={'p-4'}>
              <p>
                You won't be able to bid without a card associated with your
                account. Please add a primary card
              </p>

              <BidButton
                label="Add Card Here"
                theme="blue-light"
                link="/account/dashboard/cards"
                type="link"
                width="full"
                onClick={() => {
                  setIsOpen(false)
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </div>
  )
}

export default MissingCreditCard
