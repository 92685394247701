import React, { useState, createContext, useEffect } from 'react'
import groupByState from '@utilities/functions/groupByState'
import bidapi from '../api/bidapi'

const GlobalDataContext = createContext<{ locations: any }>({ locations: {} })

function GlobalDataProvider(props: any) {
  const [locations, setLocations] = useState<any>({})

  const refreshLocations = async () => {
    const allLocations = groupByState(
      await bidapi({
        url: `${process.env.NEXT_PUBLIC_AUCTION_API}/location/getAllLocations`,
      })
    )
    setLocations(allLocations)
  }

  useEffect(() => {
    refreshLocations()
  }, [])
  const { children } = props

  return (
    <GlobalDataContext.Provider value={{ locations } as any}>
      {children}
    </GlobalDataContext.Provider>
  )
}

export { GlobalDataContext, GlobalDataProvider }
