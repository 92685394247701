import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react'
import {
  LocationType,
  NavigationContextType,
  SidebarType,
  toggleSidebarType,
  ZipcodeType,
} from './types'
import { AuthContext } from '../AuthContext'
import getLocations, {
  getLocationByZipcode,
  getLocationsByCoordinates,
} from '@components/Location/server/getLocations'
import { Locations } from '@components/Location/location.types'
import useDidMountEffect from '@utilities/hooks/useDidMountEffect'
import useUserLocation from '@components/Location/reusable/useUserLocation'
import { NAVIGATION } from './constants'

const NavigationContext = createContext<NavigationContextType>({
  sidebar: null,
  setSidebar: () => {},
  showSidebar: false,
  setShowSidebar: () => {},
  favoriteLocations: new Set(),
  setFavoriteLocations: () => {},
  zipcode: '',
  setZipcode: () => {},
  toggleSidebar: (t) => () => {},
  handleFavoriteLocations: async () => {},
  handlePreferredZipcode: async () => false,
  locationsInArea: null,
  allLocations: [],
  navigationLinks: [],
})

const NavigationProvider = ({ children }: { children: ReactNode }) => {
  const { instance, isAuthenticated } = useContext<any>(AuthContext)
  const [geoPosition] = useUserLocation()
  const [sidebar, setSidebar] = useState<SidebarType>(null)
  const [showSidebar, setShowSidebar] = useState<boolean>(false)
  const [favoriteLocations, setFavoriteLocations] = useState<LocationType>(
    new Set()
  )
  const [zipcode, setZipcode] = useState<ZipcodeType>('')
  const [locationsInArea, setLocationsInArea] = useState<Locations | null>(null)
  const [allLocations, setAllLocations] = useState<Locations>([])

  const init = async () => {
    if (!isAuthenticated) {
      setFavoriteLocations(new Set())
      setZipcode('')
      if (allLocations.length === 0) {
        const { allLocations: allLocs } = await getLocations()
        setAllLocations(allLocs)
      }
      return
    }

    const {
      data: {
        favoriteLocations: newFavoriteLocations = [],
        preferredZipcode: newPreferredZipcode,
      },
    } = await instance.get('/bidder/get')
    const {
      allLocations: newAllLocations,
      locationsInArea: newLocationsInArea,
    } = await getLocations(newPreferredZipcode)
    setLocationsInArea(newLocationsInArea)
    setAllLocations(newAllLocations)
    setFavoriteLocations(new Set(Object.keys(newFavoriteLocations).map(Number)))
    setZipcode(newPreferredZipcode)
  }

  const refetch = async () => {
    const newLocations = zipcode
      ? await getLocationByZipcode(zipcode)
      : geoPosition?.coords
      ? await getLocationsByCoordinates(geoPosition.coords)
      : null
    setLocationsInArea(newLocations)
  }

  useEffect(() => {
    init()
  }, [isAuthenticated])

  useDidMountEffect(() => {
    refetch()
  }, [zipcode, geoPosition])

  const handleFavoriteLocations = async (locationId: number) => {
    const isChecked = favoriteLocations.has(locationId)
    const newLocations = new Set(favoriteLocations)
    newLocations[isChecked ? 'delete' : 'add'](locationId)

    await instance.post('/bidder/updateFavoriteLocations', {
      locations: Array.from(newLocations),
      preferredZipcode: zipcode,
    })
    setFavoriteLocations(newLocations)
  }

  const handlePreferredZipcode = async (newZipcode: string) => {
    const { status } = await instance.post('/bidder/updateFavoriteLocations', {
      locations: Array.from(favoriteLocations),
      preferredZipcode: newZipcode,
    })
    setZipcode(newZipcode)
    return status === 200
  }

  const toggleSidebar: toggleSidebarType = (type) => (value) => {
    if (value) {
      setSidebar(type)
    }
    setShowSidebar(Boolean(value))
  }

  return (
    <NavigationContext.Provider
      value={{
        sidebar,
        setSidebar,
        showSidebar,
        setShowSidebar,
        favoriteLocations,
        setFavoriteLocations,
        zipcode,
        locationsInArea,
        setZipcode,
        toggleSidebar,
        handleFavoriteLocations,
        handlePreferredZipcode,
        allLocations,
        navigationLinks: NAVIGATION.sidebar,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}
const useNavigation = (): NavigationContextType => {
  const context = useContext(NavigationContext)
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider')
  }
  return context
}

export { NavigationProvider, useNavigation }
