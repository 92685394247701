import { useEffect, useState, Dispatch, SetStateAction } from 'react'

type UseUserLocationReturn = [
  GeolocationPosition | undefined,
  Dispatch<SetStateAction<GeolocationPosition | undefined>>,
  GeolocationPositionError | undefined
]

const useUserLocation = (): UseUserLocationReturn => {
  const [userPosition, setUserPosition] = useState<
    GeolocationPosition | undefined
  >()
  const [error, setError] = useState<GeolocationPositionError | undefined>()

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserPosition(position)
        },
        (error) => {
          setError(error)
        }
      )
    }
  }, [])

  return [userPosition, setUserPosition, error]
}

export default useUserLocation
