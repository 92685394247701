import C from '../../constants'
import isTokenExpired from '../isTokenExpired'
import { getToken } from 'next-auth/jwt'
import { JWTSession } from '../../next-auth/types'
import { AuthOptions, getServerSession } from 'next-auth'
import authOptions from '@utilities/next-auth/auth-options'
import { checkClientUnload } from '@utilities/auth/server/checkClientUnload'

async function getServerSideAccessToken(
  context: { req: any; res: any },
  refresh = true
) {
  if (context === null) return null
  // decrypt token from cookie
  let tokens = (await getToken({
    req: context.req,
    cookieName: C.TOKEN.NAME,
  })) as JWTSession | null
  if (
    refresh &&
    isTokenExpired(tokens?.accessToken) &&
    !checkClientUnload(context.req)
  ) {
    // run jwt callback only in case the token is expired
    // the last place to check token expiration
    tokens = await getServerSession<AuthOptions, JWTSession>(
      context.req,
      context.res,
      authOptions
    )
  }
  return isTokenExpired(tokens?.accessToken) ? null : tokens?.accessToken
}

export default getServerSideAccessToken
