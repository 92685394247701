import { JWTSession } from './types'
import requestToken from '../auth/server/requestToken'
import isTokenExpiredAhead from '@utilities/auth/isTokenExpiredAhead'

const wait = (ms = 1000) => new Promise((resolve) => setTimeout(resolve, ms))

export const refreshJWTSession = async (
  token: JWTSession
): Promise<JWTSession> => {
  let response = await requestToken(token.refreshToken!)
  if (response.refreshToken) {
    return {
      ...token,
      ...response,
      error: response.success === false,
      message:
        response.success === false
          ? response.message || 'RefreshAccessTokenError'
          : null,
    }
  } else {
    await wait(1000)
    isTokenExpiredAhead(token.accessToken)
    response = await requestToken(token.refreshToken!)
    return {
      ...token,
      ...response,
      error: response.success === false,
      message:
        response.success === false
          ? response.message || 'RefreshAccessTokenError'
          : null,
    }
  }
}
