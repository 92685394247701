import { GeoLocation, Locations } from '@components/Location/location.types'
import _isArray from 'lodash/isArray'
import { sortByDistance } from '@components/Location/utils/locationFunctions'
import axios from 'axios'

const baseUrl = process.env.NEXT_PUBLIC_AUCTION_API as string
const getLocations = async (
  zipCode?: string | null,
  geoLocation?: { latitude: number; longitude: number }
): Promise<{ allLocations: Locations; locationsInArea: Locations | null }> => {
  const { data: allLocations }: { data: Locations } = await axios.get(
    `${baseUrl}/location/getAllLocations`
  )
  const locationsInArea = await getLocationByZipcode(zipCode)

  return { allLocations, locationsInArea }
}

export const getLocationByZipcode = async (
  zipCode?: string[] | string | null
) => {
  if (!zipCode) return null

  const _zipCode = _isArray(zipCode) ? zipCode[0] : zipCode

  const url = `${baseUrl}/location/getLocationsByZipMiles?zipCode=${_zipCode}&miles=30`
  const { data: locations }: { data: Locations } = await axios.get(url)
  return sortByDistance(locations).filter(({ lat, lng }) => lat && lng)
}

export const getLocationsByCoordinates = async (coord: GeoLocation) => {
  const { latitude, longitude } = coord

  const { data: locations }: { data: Locations } = await axios.get(
    '/location/getLocationsByLatLng',
    {
      baseURL: baseUrl,
      params: { miles: 30, latitude, longitude },
    }
  )
  return sortByDistance(locations).filter(({ lat, lng }) => lat && lng)
}

export default getLocations
