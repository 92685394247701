import { createContext, useCallback, useEffect, useState } from 'react'

import C from '@utilities/constants'
import _isEqual from 'lodash/isEqual'

import { useSession } from 'next-auth/react'
import fetchUser from '@utilities/axios/fetchUserAccumulative'
import getTokenTimeLeft from '@utilities/auth/getTokenTimeLeft'

import { datadogRum } from '@datadog/browser-rum'

import { AuthContextInterface } from './types'
import useInstance from './useInstance'
import { Session } from 'next-auth'
import { signOut } from 'next-auth/react'
import { useRouter } from 'next/router'

const AuthContext = createContext<AuthContextInterface | null>(null)

const AuthProvider = ({ children }: any) => {
  const { update: updateSession, status, data } = useSession()
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false)
  const session = data as Session | null
  const { pathname } = useRouter()

  const logEvent = (name: string) => {
    console.log(name, session)
    datadogRum.addAction(name, {
      status: status,
      accessToken: session?.accessToken,
      timeLeft: getTokenTimeLeft(session?.accessToken),
    })
  }

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

  const logout = () => {
    setIsLoggingOut(true)
    signOut({ redirect: false })
  }

  useEffect(() => {
    if (isLoggingOut && pathname === '/account/login') {
      setIsLoggingOut(false)
    }
  }, [pathname])

  const instance = useInstance(session, logout)

  useEffect(() => {
    logEvent(`STATUS: ${status}`)
    if (status === 'loading') return
    setIsAuthenticated(status === 'authenticated')
  }, [status])

  const refreshUserData = useCallback(async () => {
    if (!data) return null
    const userDataFetched = await fetchUser(data.accessToken)
    if (!_isEqual(userDataFetched, data.user)) {
      const { user } = (await updateSession({
        ...session,
        user: userDataFetched,
      })) || { user: null }

      return user
    }

    return userDataFetched
  }, [instance, updateSession, data])

  const login = useCallback(
    async ({
      username,
      password,
    }: {
      username: string
      password: string
    }) => {},
    []
  )

  const setIsSubscribed = async (isSubscribed: boolean) => {
    await updateSession({
      ...session,
      user: { ...session?.user, isSubscribed },
    })
  }

  return (
    <AuthContext.Provider
      value={{
        accessToken: session?.accessToken,
        userData: session?.user || C.EMPTY.USER_DETAILS,
        isAuthenticated,
        isSubscribed: session?.user?.isSubscribed || false,
        setIsSubscribed,
        instance,
        login,
        logout,
        refreshUserData,
        isLoading: !isAuthenticated && status === 'loading',
        logEvent,
        status,
        isLoggingOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext }
